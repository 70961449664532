<!-- <app-spinner class="d-flex justify-content-center align-items-center py-3" *ngIf="loading"></app-spinner>
@for (flow of flows; track $index) {

<p-dialog [(visible)]="visible2[$index]" [style]="{ width: '60vw' }" [closable]="true">
  <div *ngIf="flowDetailsData">
    <h5 class="text-center mb-3">Details of Flow</h5>
    <h5 class="text-center">
      <strong>Name</strong>:{{ flowDetailsData?.name }}
    </h5>
    <ng-container *ngFor="
        let item of flowDetailsData?.steps;
        let i = index;
        let last = last
      ">
      <div [ngClass]="{ 'dotted-border': !last }">
        <p class="text-center" *ngIf="item.delay !== null">
          <strong *ngIf="item.delay !== null">Delay</strong>:
          {{ item.delay + " " + item.delay_type }}
        </p>
        <p class="text-center">
          <strong>Email Body</strong>: {{ item?.email_body }}
        </p>
      </div>
    </ng-container>

    <div class="text-center">
      <button (click)="deleteFlow(flowDetailsData.id, $index)" class="btn btn-danger w-50">
        Delete Flow
      </button>
    </div>
  </div>
  <app-spinner class="text-center" *ngIf="!flowDetailsData"></app-spinner>
</p-dialog>

}
<div class="container">
  <p-table [value]="flows" sortField="name" sortMode="single" [scrollable]="false" rowGroupMode="subheader"
    groupRowsBy="name">
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="groupheader" let-customer>
      <tr pRowGroupHeader class="mt-5 d-flex gap-4 justify-content-between align-items-center"
        style="border-radius: 25px">
        <h5 colspan="" class="mx-4 py-1">Category : {{ customer.name }}</h5>
        <i class="bi bi-x-circle fs-4" style="color: red; margin-right: 10px; cursor: pointer"
          (click)="confirm2($event, customer.id)"></i>
      </tr>
    </ng-template>
    <ng-template pTemplate="groupfooter" let-customer> </ng-template>
    <ng-template pTemplate="body" let-customer let-rowIndex="rowIndex">
      <div class="row rowmargin">
        @for (item of customer.flows; track $index) {

        <div class="col-md-2 mb-3 col-10 card py-2 mx-3" (click)="displayFlowsDetails(item.id, $index)">
          <div class="text-capitalize">
            <div class="name">
              <li class="text-start">{{ item.name }}</li>
            </div>
          </div>
        </div>
        } @empty {
        <div class="col-md-2 mb-3 col-10 card py-2">
          <div class="">
            <span>no flow in this item</span>
          </div>
        </div>

        }
      </div>
    </ng-template>
  </p-table>
</div>
<p-toast></p-toast>
<p-confirmPopup /> -->

<!-- <app-spinner
  class="d-flex justify-content-center align-items-center py-3"
  *ngIf="loading"
></app-spinner>

<div class="container">
  <p-table
    [value]="flows"
    sortField="name"
    sortMode="single"
    [scrollable]="false"
    rowGroupMode="subheader"
    groupRowsBy="name"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="groupheader" let-customer>
      <tr
        pRowGroupHeader
        class="mt-5 d-flex gap-4 justify-content-between align-items-center"
        style="border-radius: 25px"
      >
        <h5 class="mx-4 py-1">Category : {{ customer.name }}</h5>
        <i
          class="bi bi-x-circle fs-4"
          style="color: red; margin-right: 10px; cursor: pointer"
          (click)="confirm2($event, customer.id)"
        ></i>
      </tr>
    </ng-template>
    <ng-template pTemplate="groupfooter" let-customer> </ng-template>
    <ng-template pTemplate="body" let-customer let-rowIndex="rowIndex">
      <div class="row rowmargin d">
        <ng-container *ngFor="let item of customer.flows; let index = index">
          <div
            class="col-md-2 mb-3 col-10 card py-2 mx-3 d-flex align-items-center"
            (click)="displayFlowsDetails(item.id, index)"
          >
            <div class="text-capitalize">
              <div class="name">
                <li class="text-start">{{ item.name }}</li>
              </div>
            </div>
          </div>

          <p-dialog
            [(visible)]="visibleDialogs[index]"
            [style]="{ width: '60vw' }"
            [closable]="true"
          >
            <div *ngIf="flowDetailsData[index]">
              <h5 class="text-center mb-3">Details of Flow</h5>
              <h5 class="text-center">
                <strong>Name</strong>: {{ flowDetailsData[index]?.name }}
              </h5>
              <ng-container
                *ngFor="
                  let step of flowDetailsData[index]?.steps;
                  let i = index;
                  let last = last
                "
              >
                <div [ngClass]="{ 'dotted-border': !last }">
                  <p class="text-center" *ngIf="step.delay !== null">
                    <strong *ngIf="step.delay !== null">Delay</strong>:
                    {{ step.delay + " " + step.delay_type }}
                  </p>
                  <p class="text-center">
                    <strong>Email Body</strong>: {{ step?.email_body }}
                  </p>
                </div>
              </ng-container>
              <div class="text-center">
                <button
                  (click)="deleteFlow(flowDetailsData[index].id, index)"
                  class="btn btn-danger w-50"
                >
                  Delete Flow
                </button>
              </div>
            </div>
            <app-spinner
              class="text-center"
              *ngIf="!flowDetailsData[index]"
            ></app-spinner>
          </p-dialog>
        </ng-container>

        <div
          *ngIf="customer.flows.length === 0"
          class="col-md-2 mb-3 col-10 card py-2"
        >
          <div>
            <span>No flow in this item</span>
          </div>
        </div>
      </div>
    </ng-template>
  </p-table>
</div>

<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup> -->
<!-- <app-spinner
  class="d-flex justify-content-center align-items-center py-3"
  *ngIf="loading"
></app-spinner>

<div class="container">
  <p-table
    [value]="flows"
    sortField="name"
    sortMode="single"
    [scrollable]="false"
    rowGroupMode="subheader"
    groupRowsBy="name"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="groupheader" let-customer>
      <tr
        pRowGroupHeader
        class="mt-5 d-flex gap-4 justify-content-between align-items-center"
        style="border-radius: 25px"
      >
        <h5 class="mx-4 py-1">Category : {{ customer.name }}</h5>
        <i
          class="bi bi-x-circle fs-4"
          style="color: red; margin-right: 10px; cursor: pointer"
          (click)="confirm2($event, customer.id)"
        ></i>
      </tr>
    </ng-template>
    <ng-template pTemplate="groupfooter" let-customer> </ng-template>
    <ng-template pTemplate="body" let-customer let-rowIndex="rowIndex">
      <div class="d-flex flex-row rowmargin">
        <ng-container *ngFor="let item of customer.flows; let index = index">
          <div
            class="col-md-2 mb-3 col-10 card py-2 mx-3 align-items-center"
            (click)="displayFlowsDetails(item.id, index)"
          >
            <div class="text-capitalize">
              <div class="name">
                <li class="text-start" style="word-wrap: break-word">
                  {{ item.name }}
                </li>
              </div>
            </div>
          </div>

          <p-dialog
            [(visible)]="visibleDialogs[index]"
            [style]="{ width: '60vw' }"
            [closable]="true"
          >
            <div *ngIf="flowDetailsData[index]">
              <h5 class="text-center mb-3">Details of Flow</h5>
              <h5 class="text-center">
                <strong>Name</strong>: {{ flowDetailsData[index]?.name }}
              </h5>
              <ng-container
                *ngFor="
                  let step of flowDetailsData[index]?.steps;
                  let i = index;
                  let last = last
                "
              >
                <div [ngClass]="{ 'dotted-border': !last }">
                  <p class="text-center" *ngIf="step.delay !== null">
                    <strong *ngIf="step.delay !== null">Delay</strong>:
                    {{ step.delay + " " + step.delay_type }}
                  </p>
                  <p class="text-center">
                    <strong>Email Body</strong>: {{ step?.email_body }}
                  </p>
                </div>
              </ng-container>
              <div class="text-center">
                <button
                  (click)="deleteFlow(flowDetailsData[index].id, index)"
                  class="btn btn-danger w-50"
                >
                  Delete Flow
                </button>
              </div>
            </div>
            <app-spinner
              class="text-center"
              *ngIf="!flowDetailsData[index]"
            ></app-spinner>
          </p-dialog>
        </ng-container>

        <div
          *ngIf="customer.flows.length === 0"
          class="col-md-2 mb-3 col-10 card py-2"
        >
          <div>
            <span>No flow in this item</span>
          </div>
        </div>
      </div>
    </ng-template>
  </p-table>
</div>

<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup> -->
<app-spinner
  class="d-flex justify-content-center align-items-center py-3"
  *ngIf="loading"
></app-spinner>

<div class="container">
  <p-table
    [value]="flows"
    sortField="name"
    sortMode="single"
    [scrollable]="false"
    rowGroupMode="subheader"
    groupRowsBy="name"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="groupheader" let-customer>
      <tr
        pRowGroupHeader
        class="mt-5 d-flex gap-4 justify-content-between align-items-center"
        style="border-radius: 25px"
      >
        <h5 class="mx-4 py-1">Category : {{ customer.name }}</h5>
        <i
          class="bi bi-x-circle fs-4"
          style="color: red; margin-right: 10px; cursor: pointer"
          (click)="confirm2($event, customer.id)"
        ></i>
      </tr>
    </ng-template>
    <ng-template pTemplate="groupfooter" let-customer> </ng-template>
    <ng-template pTemplate="body" let-customer let-rowIndex="rowIndex">
      <div class="d-flex flex-wrap rowmargin">
        <ng-container *ngFor="let item of customer.flows; let index = index">
          <div
            class="col-md-2 mb-3 col-10 card py-2 mx-3 d-flex flex-column align-items-center"
            (click)="displayFlowsDetails(item.id, index)"
          >
            <div class="text-capitalize">
              <div class="name">
                <li class="text-start">{{ item.name }}</li>
              </div>
            </div>
          </div>

          <p-dialog
            [(visible)]="visibleDialogs[index]"
            [style]="{ width: '60vw' }"
            [closable]="true"
          >
            <div *ngIf="flowDetailsData[index]">
              <h5 class="text-center mb-3">Details of Flow</h5>
              <h5 class="text-center">
                <strong>Name</strong>: {{ flowDetailsData[index]?.name }}
              </h5>
              <ng-container
                *ngFor="
                  let step of flowDetailsData[index]?.steps;
                  let i = index;
                  let last = last
                "
              >
                <div [ngClass]="{ 'dotted-border': !last }">
                  <p class="text-center" *ngIf="step.delay !== null">
                    <strong *ngIf="step.delay !== null">Delay</strong>:
                    {{ step.delay + " " + step.delay_type }}
                  </p>
                  <p class="text-center">
                    <strong>Email Body</strong>: {{ step?.email_body }}
                  </p>
                </div>
              </ng-container>
              <div class="text-center">
                <button
                  (click)="deleteFlow(flowDetailsData[index].id, index)"
                  class="btn btn-danger w-50"
                >
                  Delete Flow
                </button>
              </div>
            </div>
            <app-spinner
              class="text-center"
              *ngIf="!flowDetailsData[index]"
            ></app-spinner>
          </p-dialog>
        </ng-container>

        <div
          *ngIf="customer.flows.length === 0"
          class="col-md-2 mb-3 col-10 card py-2"
        >
          <div>
            <span>No flow in this item</span>
          </div>
        </div>
      </div>
    </ng-template>
  </p-table>
</div>

<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
