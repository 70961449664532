<div class="d-flex justify-content-between gap-3">
  <p-card class="flex-grow-1">
    <p-skeleton height="4rem" styleClass="mb-2"></p-skeleton>
  </p-card>
  <p-card class="flex-grow-1">
    <p-skeleton height="4rem" styleClass="mb-2"></p-skeleton>
  </p-card>
  <p-card class="flex-grow-1">
    <p-skeleton height="4rem" styleClass="mb-2"></p-skeleton>
  </p-card>
</div>
