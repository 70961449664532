<main>
  @if (shouldRender) {
  <nav class="py-3">
    <app-navbar></app-navbar>
  </nav>
  }
  <section class="main">
    <router-outlet></router-outlet>
  </section>
</main>
<p-toast
  [showTransformOptions]="'translateY(100%)'"
  [showTransitionOptions]="'1000ms'"
  [hideTransitionOptions]="'1000ms'"
  [showTransformOptions]="'translateX(100%)'"
></p-toast>
