import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from 'primeng/api';

export const authGuard: CanActivateFn = (route, state) => {
  const cookiesService = inject(CookieService);
  const messageService = inject(MessageService);
  const router = inject(Router);
  if (cookiesService.get('token') || sessionStorage.getItem('token')) {
    return true;
  } else {
    messageService.add({
      severity: 'warn',
      summary: 'Oops',
      detail: 'Please Login To Access This Page',
    });
    router.navigate(['/login']);
    return false;
  }
};
