import { Component } from '@angular/core';
import { CardModule } from 'primeng/card';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-flow-skeleton',
  standalone: true,
  imports: [SkeletonModule, CardModule],
  templateUrl: './flow-skeleton.component.html',
  styleUrl: './flow-skeleton.component.scss',
})
export class FlowSkeletonComponent {}
