<!-- <div class="container">
  <div class="row align-items-center">
    <div class="col-md-3 flex-grow-1">
      <div class="image">
        <img routerLink="/all-flows" src="../assets/logo.png" alt="logo" />
      </div>
    </div>
    <div class="col-md-3 flex-grow-1">
      <ul class="d-flex m-0 p-0 justify-content-between">
        <li routerLink="/all-flows">All Flows</li>
        <li routerLink="/create-flow">Create Flow</li>
        <li routerLink="/analytics">Analytics</li>
      </ul>
    </div>
    <div class="col-md-3 flex-grow-1">
      @if (!isTokenExists) {
      <div class="button d-flex justify-content-center align-items-center">
        <button routerLink="/login" class="btn btn-primary w-50">login</button>
      </div>
      }@else {
      <div class="button d-flex justify-content-center align-items-center">
        <button
          pButton
          (click)="logOut()"
          class="w-25 rounded-3 d-flex justify-content-center logout-btn"
        >
          logout
        </button>
      </div>
      }
    </div>
  </div>
</div> -->
<nav class="navbar navbar-expand-lg">
  <div class="container">
    <a class="navbar-brand" routerLink="/all-flows">
      <img src="../assets/logo.png" alt="logo" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-0 mb-lg-0">
        <li class="nav-item mx-5">
          <a class="nav-link" routerLink="/all-flows">All Flows</a>
        </li>
        <li class="nav-item mx-5">
          <a class="nav-link" routerLink="/create-flow">Create Flow</a>
        </li>
        <li class="nav-item mx-5">
          <a class="nav-link" routerLink="/analytics">Analytics</a>
        </li>
      </ul>
      <div class="d-flex mx-5">
        <ng-container *ngIf="!isTokenExists; else loggedIn">
          <button routerLink="/login" class="btn btn-primary">Login</button>
        </ng-container>
        <ng-template #loggedIn>
          <button (click)="logOut()" class="logout-btn">Logout</button>
        </ng-template>
      </div>
    </div>
  </div>
</nav>
