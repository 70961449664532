import { CommonModule } from '@angular/common';
import { Component, DoCheck } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [RouterLink, ButtonModule, CommonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements DoCheck {
  isTokenExists = false;
  constructor(private cookieService: CookieService, private router: Router) {
    if (cookieService.get('token') || sessionStorage.getItem('token')) {
      this.isTokenExists = true;
    }
  }
  ngDoCheck(): void {
    // console.log(this.isTokenExists);
  }
  logOut(): void {
    this.cookieService.deleteAll();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }
}
