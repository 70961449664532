import {
  Component,
  OnInit,
  ViewEncapsulation,
  viewChildren,
} from '@angular/core';
import { FlowsService } from '../../services/flows.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { AllFlows } from '../../interfaces/all-flows';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { FlowSkeletonComponent } from '../utilities/flow-skeleton/flow-skeleton.component';
import { Dialog, DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { ToastService } from '../../services/toast.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    FlowSkeletonComponent,
    DialogModule,
    ButtonModule,
    TableModule,
    SpinnerComponent,
    ConfirmPopupModule,
    ToastModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService, MessageService],
})
export class HomeComponent implements OnInit {
  flowsSubscribtion!: Subscription;
  deleteFlowsSubscribtion!: Subscription;
  flowstest: AllFlows[] = [];
  steps: any;
  visible: boolean[] = [];
  // visible2: boolean[] false[];
  visibleDialogs: boolean[] = [];

  display: boolean = false;
  loading: boolean = false;
  cate: any = [];
  flows: any;
  flowDetailsData!: any;
  visible2: boolean[] = [];
  constructor(
    private flowService: FlowsService,
    private toastService: ToastService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}
  ngOnInit(): void {
    this.getAllFlows();
  }
  getAllFlows() {
    this.flowsSubscribtion = this.flowService.getFlow().subscribe({
      next: (res: { data: AllFlows[]; status: string; result: number }) => {
        this.flows = res.data;
        this.steps = res.data;
        this.cate = res.data;

        this.visible.fill(false, res.data.length);
        this.visibleDialogs = new Array(this.flows.length).fill(false);
        this.flowDetailsData = new Array(this.flows.length).fill(null);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
  // flowDetails(index: number) {
  //   this.visible[index] = true;
  // }

  deleteFlow(id: number, index: any) {
    this.deleteFlowsSubscribtion = this.flowService.deleteFlow(id).subscribe({
      next: (res) => {
        console.log(res);
        if (res.status == 204) {
          console.log('deleted');
          this.visible.fill(false);
          this.visible2[index] = false;

          this.getAllFlows();
        }
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        console.log('ana 5lst');
      },
    });
  }

  // displayFlowsDetails(flowId: number, index: number) {

  //   this.visible2[index] = true;
  //   console.log(this.visible2[index])
  //   this.flowService.getOneFlow(flowId).subscribe({
  //     next: (res) => {
  //       console.log(res.body.data, '😒😒😒😒😒')
  //       this.flowDetailsData = res.body.data
  //       console.log(this.flowDetailsData)

  //     }
  //   })

  // }

  displayFlowsDetails(flowId: number, index: number) {
    this.visibleDialogs[index] = true;
    this.flowService.getOneFlow(flowId).subscribe({
      next: (res) => {
        this.flowDetailsData[index] = res.body.data;
      },
    });
  }

  confirm2(event: Event, id: number) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Do you want to delete this Category?',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-sm',
      accept: () => {
        this.loading = true; // Start loading indicator
        this.deletecate(id);
      },
      reject: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Rejected',
          detail: 'You have rejected',
          life: 3000,
        });
      },
    });
  }
  deletecate(ID: number) {
    if (this.deleteFlowsSubscribtion && !this.deleteFlowsSubscribtion.closed) {
      return;
    }

    this.deleteFlowsSubscribtion = this.flowService
      .Deletecategory(ID)
      .subscribe(
        (res: any) => {
          console.log(res);
          if (res.status === 204) {
            console.log('deleted');
            this.visible.fill(false);
            this.getAllFlows();
            this.messageService.add({
              severity: 'info',
              summary: 'Confirmed',
              detail: 'This Category deleted',
              life: 3000,
            });
          }
          this.loading = false; // Stop loading indicator on success
        },
        (err) => {
          console.log(err);
          const errorMessage = err.message || 'An error occurred';
          this.toastService.showError(err.error.message);
          this.loading = false; // Stop loading indicator on error
        },
        () => {
          console.log('Operation completed');
          this.loading = false; // Stop loading indicator in completion callback
          if (this.deleteFlowsSubscribtion) {
            this.deleteFlowsSubscribtion.unsubscribe();
          }
        }
      );
  }
}
